<template>
  <v-container>
    <h1>Attendere...</h1>
    <QsRemoteAccess :dest="$route.params.dest" />
  </v-container>
</template>

<script>
import QsRemoteAccess from '@components/sessions/QsRemoteAccess.vue'
export default {
  name: 'Remoteaccess',
  components: { QsRemoteAccess },
}
</script>

<style scoped></style>
