<template>
  <form ref="remoteaccess" action="https://support.communitake.com/csrweb/" method="POST">
    <input type="hidden" name="customer" value="sosdigital" />
    <input type="hidden" name="user" value="operatore1" />
    <input type="hidden" name="password" value="Operatore2!" />
    <input type="hidden" name="noauthtoken" value="true" />
    <input type="hidden" name="remoteId" :value="dest" />
    <input type="hidden" name="remoteIdType" value="MSISDN" />
    <input type="hidden" name="SEND_SMS_TYPE" value="2" />
  </form>
</template>

<script>
export default {
  name: 'QsRemoteAccess',
  props: {
    dest: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$refs.remoteaccess.submit();
  },
};
</script>

<style scoped></style>
